.home-page {
    display: flex;
    flex-direction: row-reverse;
    align-items: center; // to center of box / middle of page
    justify-content: center; //centers boxes
//     margin-top: 50px;
//     margin-left: 64px;
//     padding: 1vw;
//     text-align: left;
//     //max-height: vh; /* Maximum height for the text zone */
    // overflow-y: scroll; /* Enable vertical scrolling */

    .headshot {
        // flex: 1;
        z-index: 2;
        display: flex;
        justify-content:left; //centers horzizontally
        // align-items: center;
    //     margin-left: 5vw; /* Adjust the offset from the left */
        width: 35%; /* Adjust the width of the container */
        height: auto; /* Adjust the width of the container */
    //     margin-top: 20vw; /* Add space above the picture */
    //     margin-bottom: 30vw; /* Add space below the picture */
    //     padding-right: 7vw;
        img {
            width:78%;
            // height: 90%;
            // max-height: 90%;
            display: flex;
            opacity: 200;
            // max-height: ;

            // align-items: center;
            // justify-content: center;
        }
    }
    .text-zone {
        // flex: 1;
    //     padding: 1vw;
        margin-left: 56px; //align with body tags (distance tags from left - width of nav bar)
        width: 65%; /* Ensures the text zone does not exceed half the screen width */

        h1 {
            color: #fff;
            font-size: 550%; /* Relative unit to make font-size responsive */
            font-family: 'Coolvetica';
            font-weight: 400;
            position: relative;
            // margin-top: 4vw;
            // max-width: 100%; /* Ensure h2 does not exceed the container width */
            word-wrap: break-word; /* Wrap the text if it exceeds the container width */  
        }
        h1::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #ffffff;
            font-size: 50%; /* Relative unit */
            position: absolute;
            top: -20px;
            left: -20px;
            opacity: 0.6;
        }
        h1::after {
            content: '</h1>';
            font-family: 'La Belle Aurore';
            color: #ffffff;
            font-size: 50%; /* Relative unit */
            position: absolute;
            bottom: -25px;
            left: -20px;
            opacity: 0.6;
        }     
        h2 {
            width: 100%;
            // text-align: center;
            color: #f3aca6;
            font-weight: 750;
            // margin: 64px;
            font-size: 250%; /* Relative unit */
            font-family: Georgia;
            letter-spacing: 0.05vw;
            margin-top: 0px;
            margin-bottom: 0px;
            // max-width: 90%; /* Ensure h2 does not exceed the container width */
            word-wrap: break-word; /* Wrap the text if it exceeds the container width */
        }
        .button{
            display: flex;
            align-items: center;            
            .flat-button {
                color: #f3aca6;
                display: inline;
                margin: 40px auto 0px;
                font-size: 175%;
                font-weight: 750;
                // letter-spacing: 0.07vw;
                font-family: sans-serif;
                text-decoration: none;
                // margin: 64px;
                border: 4px solid #f3aca6;
                // margin-top: 0vw;
                // padding-left: 0.75vw;
                // padding-right: 0.75vw;
                padding: 1% 2%;
                // padding-top: 0.5vw;
                // padding-bottom: 0.5vw;
                // margin-bottom: 2vw;
                // display: inline-block;
                // background-color: transparent; /* Ensure the background is transparent */
                // white-space: nowrap;
                border-radius: 20px;
            }
            .flat-button:hover {
                background: #f3aca6;
                color: #fff;
            }
        }
    }
}
/* Media Queries for responsiveness */
@media (max-width: 1024px) {
    .home-page {
        //flex-direction: column;
        // justify-content: center;
//         text-align: center;

        // .headshot {
        //     justify-content: center;
        //     max-height: 50%;
            // width: auto;
        //     aspect-ratio: 1;
        //     img{
        //         aspect-ratio: 1;
        //         max-height: fit-content;
        //         }
        //     order: 1;
        // //         margin-top: 10vw;
        // //         margin-left: 0;
        // //         margin-bottom: 1vw; /* Add some space below the image */
        // }
        .text-zone {
        //     order: 2;
        //     margin-left: 0px;
        //     height: 50%;
        //     width: auto;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     align-items: center;
            h1 {
        //         width: auto;
        //         margin:30px 45px;
        //         text-align: left;
                font-size: 400%; /* Adjust font-size for smaller screens */
        //         // max-width: 90%;
        //         // word-wrap: break-word;
            }
            h1::before {
                font-size: 45%; /* Relative unit */
                top: -15px;
                left: -15px;
            }
            h1::after {
                font-size: 45%; /* Relative unit */
                bottom: -20px;
                left: -15px;
            }
            h2 {
                margin-left: -56px;
                font-weight: 700;
        //         // margin: 64px;
                font-size: 170%; /* Relative unit */
        //         font-family: Georgia;
        //         letter-spacing: 0.05vw;
                text-align: center;
        //         margin: 0px auto;
        //         // margin-top: 0px;
        //         // margin-bottom: 0px;
        //         // max-width: 90%; /* Ensure h2 does not exceed the container width */
        //         // word-wrap: break-word; /* Wrap the text if it exceeds the container width */
            }
            .button{
                margin-left: -64px;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         width: 22%;            
                .flat-button {
                    margin: 10px auto;
                    font-size: 96%;
                    font-weight: 700;
                    border: 3px solid #f3aca6;
        //             padding: 4% 8%;
        //             border-radius: 20px;
                }

            }

    
    //         padding: 1vw;
    //         max-width: 80%; /* Allow text zone to take full width on smaller screens */
    //         padding-right: 10vw;
    //         padding-bottom: calc(200px + 10vh);
    
        }
    }



    



//     h2 {
//         font-size: 5vw; /* Adjust font-size for smaller screens */
//         margin:64px;
//         margin-left: 2vw;
//         text-align: center;
//     }

//     .flat-button {
//         font-size: 3vw;
//         font-weight: 750;
//         letter-spacing: 0.07vw;
//         font-family: sans-serif;
//         text-decoration: none;
//         margin: 0px;
//         border: 0.8vw solid #f3aca6;
//         margin-top: 0vw;
//         padding-left: 1.25vw;
//         padding-right: 1.25vw;
//         padding-top: 0.5vw;
//         padding-bottom: 0.5vw;
//         margin-bottom: 5vw;
//         display: inline-block;
//         background-color: transparent; /* Ensure the background is transparent */
//         white-space: nowrap;
//     }

    
}

@media (max-width: 500px) {
    .home-page {
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        overflow-y: scroll;
            .headshot {
                justify-content: center;
                aspect-ratio: 1;
                width: 60%;
                .img{
                    width: auto;
                    height: 100%;
                    // object-fit: contain;
                }
            // width: 55%; /* Adjust the width of the container */
            // height: 100%;
            order: 1;
            // margin-top: 10vw;
            // margin-left: 0;
            // margin-bottom: 10vw; /* Add some space below the image */
        }
        .text-zone {
            margin-left: 0px;
            order: 2;
            width: 80%;
    //         padding: 1vw;
    //         max-width: 80%; /* Allow text zone to take full width on smaller screens */
    //         padding-right: 10vw;
    //         padding-bottom: calc(200px + 10vh);
            h1 {
                font-size: 350%; /* Relative unit to make font-size responsive */
                font-weight: 350;
            }
            h1::before {
                font-size: 45%; /* Relative unit */
                top: -10px;
                left: -15pxpx;
            }
            h1::after {
                font-size: 45%; /* Relative unit */
                bottom: -15px;
                left: -15px;
            }
            h2 {
                margin-left: 0px;
                margin-top: 5px;
                font-weight: 750;
        //         // margin: 64px;
                font-size: 190%; /* Relative unit */
        //         font-family: Georgia;
        //         letter-spacing: 0.05vw;
                text-align: center;
            }
            .button{
                margin-left: 0px;
                
                .flat-button {
                    margin: 20px auto;
                    font-size: 150%;
                    font-weight: 700;
                    border: 3px solid #f3aca6;
                }
            }
        }
    }

    

//     h1 {
//         font-size: 8vw; /* Adjust font-size for smaller screens */
//         max-width: 90%;
//         word-wrap: break-word;
//     }

//     h1::before, h1::after {
//         font-size: 4vw; /* Adjust pseudo-element font-size for smaller screens */
//     }

//     h2 {
//         font-size: 5vw; /* Adjust font-size for smaller screens */
//         margin:64px;
//         margin-left: 2vw;
//         text-align: center;
//     }

//     .flat-button {
//         font-size: 3vw;
//         font-weight: 750;
//         letter-spacing: 0.07vw;
//         font-family: sans-serif;
//         text-decoration: none;
//         margin: 0px;
//         border: 0.8vw solid #f3aca6;
//         margin-top: 0vw;
//         padding-left: 1.25vw;
//         padding-right: 1.25vw;
//         padding-top: 0.5vw;
//         padding-bottom: 0.5vw;
//         margin-bottom: 5vw;
//         display: inline-block;
//         background-color: transparent; /* Ensure the background is transparent */
//         white-space: nowrap;
//     }

}