.webpage{ //all the content of the page
    width:100%;
    height:100%;
    .page { //the whole page
        // background-color: red; //tester color
        width: 100%; //full width of page
        height: 100%; //full height of page
        position: absolute; //do not change with viewport
        z-index: 0;
        .tags { //all 4 tags
            color: #ffffff;
            opacity: 0.6;
            position: absolute;
            left: 120px;
            font-size: 18px;
            font-family: 'La Belle Aurore';
        }
        .top-tags { //top 2 tags
            top: 35px;
        }
        .bottom-tags { //bottom 2 tags
            bottom: 15px;
        }
        .outer-tag-html { //outer html tags
            margin-left: -20px;
        } 
    }
    .bgButterflies{
        z-index: -1;
        opacity: 10%;
        scale: 150%;
        color: #f2c5c7;
        position: absolute;
    }
    #B1{
        rotate:-30deg;
        translate: 200px 10vh;
    }
    #B2{
        rotate:30deg;
        translate: 450px 30vh;
    }
    #B3{
        rotate:-30deg;
        translate: 200px 50vh;
    }
    #B4{
        rotate:30deg;
        translate: 450px 70vh;
    } 
}

.container { //*need to test when one elements
    // background-color: aqua; //tester color
    width: calc(100% - 64px);
    will-change: contents;
    height: calc(100% - 160px);
    // min-height: 566px;
    position: absolute;
    top: 90px; //font size * 2 (2 tags) + top spacing
    bottom: 70px;
    margin: 0 auto;
    left: 64px;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}



::-webkit-scrollbar {
    width: 20px;
  }
  
/* Track */
::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey; 
border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #f3aca6; 
border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: white; 
}

//tested for 450-768px
@media (max-width: 1024px) {
    .webpage{
        .page { //the whole page
            .tags { //all 4 tags
                left: 50px; //changes based on sidebar
                font-size: 25px;
            }
            .top-tags { //top 2 tags
                top: 120px;
            }
            .bottom-tags { //bottom 2 tags
                bottom: 40px;
            }
            .outer-tag-html { //outer html tags
                margin-left: -30px;
            } 
        }
        .bgButterflies{
            scale: 80%;
        }
        #B1{
            translate: 25vw 10vh;
        }
        #B2{
            translate: 45vw 30vh;
        }
        #B3{
            translate: 25vw 50vh;
        }
        #B4{
            translate: 45vw 70vh;
        }
    }
    .container { //*need to test when one elements
        width: 100%; //set back to 100%
        height: calc(100% - 300px); //100% of screen - top and bottom spacing
        // min-height: 566px;
        left: 0px; // set back to 0
        top: 190px; //height of top-tags+ top spacing + height of nav bar
        bottom: 110px; //height of bottom-tags + bottom spacing
    }
}

//tested for 315-450 px
@media (max-width: 500px) {
    .webpage{
        .page { //the whole page
            .tags { //all 4 tags
                left: 50px; //changes based on sidebar
                font-size: 25px;
            }
            .top-tags { //top 2 tags
                top: 110px;
            }
            .bottom-tags { //bottom 2 tags
                bottom: 30px;
            }
            .outer-tag-html { //outer html tags
                margin-left: -30px;
            } 
        }
        .bgButterflies{
            scale: 80%;
        }
        #B1{
            translate: 10vw 10vh;
        }
        #B2{
            translate: 25vw 30vh;
        }
        #B3{
            translate: 10vw 50vh;
        }
        #B4{
            translate: 25vw 70vh;
        }
    }
    .container { //*need to test when one elements
        height: calc(100% - 280px); //100% of screen - top and bottom spacing
        // min-height: 566px;
        top: 180px; //height of top-tags+ top spacing + height of nav bar
        bottom: 100px; //height of bottom-tags + bottom spacing
    }
}







// @media (max-width: 450px) {
//     .bgButterflies{
//             scale: 70%;
//             .solid-logo1{
//                 translate: 60vw 25vh;
//             }
//             .solid-logo2{
//                 translate: 18vw 35vh;
//             }
//             .solid-logo3{
//                 translate: 60vw 40vh;
//             }
//             .solid-logo4{
//                 translate: 18vw -90vh;
//             }
//         }
    
// }